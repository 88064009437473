<template>
    <AppPaginatedGrid
        v-if="!loading"
        ref="grid"
        :service="service"
        permission="gestaosesmt_gro_cliente"
        :showActionItems="false"
        :expander="false"
        :hasFiltro="false"
        :filtrosExtras="{ ...filtrosExtras, ativo: true }"
    >
        <template #anotherFilters>
            <HeaderPanel tooltip="Listagem de clientes" :title="'Clientes'" nomeTelaDoManual="customers-gro" />
            <FiltrosCliente v-model:filtrosExtras="filtrosDoComponente" :statusOptions="statusCustomerOptions" @loadCustomers="load" />
        </template>
        <template #columns>
            <Column field="name" header="Cliente" :sortable="true" sortField="customers.name" />
            <Column field="statusGro" header="Status" :sortable="true" sortField="customers.status_gro">
                <template #body="{ data, field }">
                    <StatusGrid :status="getStatusComTodasAsConfigs(data[field])" />
                </template>
            </Column>
            <Column style="text-align: right">
                <template #body="{ data }">
                    <Button
                        label="Unidades"
                        v-tooltip.focus.top="!checkPermission('gestaosesmt_gro_unidade:view') ? 'Sem permissão' : ''"
                        class="p-button-link"
                        @click="goToBranches(data.id)"
                    />
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script setup>
import HeaderPanel from './components/HeaderPanel.vue';
import FiltrosCliente from './components/FiltrosCliente.vue';
import StatusGrid from './components/StatusGrid.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import SesmtService from '../../services/SesmtService';
import { useRouter } from 'vue-router';
import { getClientSesmt } from '../../services/http-sesmt';
import { useStore } from 'vuex';
import StatusGroCustomerEnum from '../../enums/StatusGroCustomer';
import { checkPermission } from '../../common/check-permission';

const filtrosDoComponente = ref({});
const filtrosExtras = ref({});

const store = useStore();
const queryParams = ref({});

const service = ref(new SesmtService('/customers'));
const router = useRouter();
const grid = ref(null);
const loading = ref(false);
const statusCustomerOptions = ref([]);
const styleMapStatus = {
    [StatusGroCustomerEnum.NOVO_CLIENTE]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroCustomerEnum.PENDENTE]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroCustomerEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

async function load(removerFiltros = false) {
    if (removerFiltros) {
        filtrosDoComponente.value = {};
        atualizarQueryParamsStore({});
    }

    filtrosExtras.value = {
        grupoId: filtrosDoComponente.value?.grupo?.id,
        customerId: filtrosDoComponente.value?.customer?.id,
        status: filtrosDoComponente.value?.status
    };

    await grid.value?.load();
}

function goToBranches(id) {
    if (!checkPermission('gestaosesmt_gro_unidade:view')) return;

    const currentRoute = router.currentRoute;
    const basePath = currentRoute.value.path.endsWith('/') ? currentRoute.value.path : currentRoute.value.path + '/';
    router.push(`${basePath}${id}/unidades`);
}

async function atualizarQueryParams() {
    const { grupoId, customerId, status } = filtrosExtras.value;

    atualizarQueryParamsStore({
        grupo: grupoId ? JSON.stringify(grupoId) : undefined,
        customer: customerId ? JSON.stringify(customerId) : undefined,
        status
    });

    await router.replace({
        query: {
            grupo: grupoId ? JSON.stringify(grupoId) : undefined,
            customer: customerId ? JSON.stringify(customerId) : undefined,
            status
        }
    });
}

function atualizarQueryParamsStore(params) {
    store.dispatch('setQueryParamsTelaClientesGro', params);
}

function getStatusCustomerOptions() {
    statusCustomerOptions.value = Object.keys(StatusGroCustomerEnum).map(function (type) {
        return {
            label: `${StatusGroCustomerEnum[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusGroCustomerEnum[type]]
        };
    });
}

function getStatusComTodasAsConfigs(status) {
    return statusCustomerOptions.value.find((p) => p.value === status) ?? { label: 'NÃO INFORMADO OU INVÁLIDO' };
}

onMounted(async () => {
    store.dispatch('setQueryParamsTelaUnidadesGro', {});
    store.dispatch('setQueryParamsTelaInventarioRiscoGro', {});

    getStatusCustomerOptions();

    const routeQueryParams = router.currentRoute.value?.query;
    queryParams.value = Object.keys(routeQueryParams).length ? routeQueryParams : store.getters.queryParamsTelaClientesGro;

    loading.value = true;
    if (Object.keys(queryParams.value).length) {
        let grupo;
        let customer;

        if (queryParams.value?.grupo) {
            const { data } = await getClientSesmt().get(`/grupos/${queryParams.value?.grupo}`);
            grupo = data;
        }

        if (queryParams.value?.customer) {
            const { data } = await getClientSesmt().get(`/customers/${queryParams.value?.customer}`);
            customer = data;
        }

        filtrosExtras.value = {
            grupoId: grupo?.id,
            customerId: customer?.id,
            status: queryParams.value?.status
        };

        filtrosDoComponente.value = {
            grupo: grupo,
            customer: customer,
            status: queryParams.value?.status
        };

        await atualizarQueryParams();
    } else {
        filtrosDoComponente.value = {
            grupo: queryParams.value?.grupo,
            customer: queryParams.value?.customer,
            status: queryParams.value?.status
        };

        await atualizarQueryParams();
    }

    loading.value = false;
});

onUnmounted(() => {
    const fullPath = router.currentRoute.value.fullPath;

    if (!fullPath.includes('gestao-risco')) {
        atualizarQueryParamsStore({});
    }
});
</script>
